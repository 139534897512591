<template lang="pug">
div
  b-row
    b-col
      h6(v-if="ot.tecnico_asignado") Asignado a {{ ot.tecnico_asignado.email }}
      h6(v-else) No asignado aún
    b-col.d-flex.justify-content-end
      ActionSelectTechnician(:ot="ot", @changed="() => $emit('changed')", :updateOnly="true")
  hr
  .loader(v-if="loading")
    b-spinner(label='Cargando...' variant="primary")
  div
    .d-flex.flex-row
      b-icon.mr-2(icon="laptop" variant="primary" font-scale="1.5")
      h3.title-data Reporte de Falla
    div(class="reporte-text") {{ot.reporte_falla_cliente}}
  hr
  div(v-if="!diagnostic && !loading")
      p.mb-5 No se ha realizado un diagnóstico aún
      .d-flex.justify-content-center(v-if="canEdit")
      p(v-else) Requiere estado "En Reparación" para comenzar a trabajar (retirar de bodega)
  div(v-if="diagnostic && !loading")
    div.d-flex.justify-content-between
      div.head
        b-icon.mr-2(icon="bag-plus" variant="primary" font-scale="1.5")
        h4.mb-0.title(style="font-size: 1.2em;") Diagnóstico
        UpdateDiagnostic(:diagnostic="diagnostic")
    hr
    div.d-flex.justify-content-between.mb-3.row
      div.details.col-4
        label.title Status
        span {{diagnostic.resultado}}
      div.details.col-4
        label.font-weight-bold Fecha
        span {{diagnostic.fecha_creacion | beautyDate}}
      div.details.col-4
        label.font-weight-bold Solución Macro
        span {{diagnostic.solucion_macro? diagnostic.solucion_macro : '-'}}
    div.d-flex.justify-content-between.mb-3.row
      div.details.col-4
        label.font-weight-bold ¿Se pudo replicar la falla?
        span {{diagnostic.replicar_falla? 'Si' : 'No'}}
      div.details.col-4
        label.font-weight-bold Informe
        span {{diagnostic.informe}}
      div.details.col-4
        label.font-weight-bold Solución
        span {{diagnostic.solucion ? diagnostic.solucion : '-'}}
    div.d-flex.justify-content-between.mb-3.row
      div.details.col-4(v-if="this.ot.estado.estado && this.ot.estado.estado.nombre === 'Rechazado'")
        label.font-weight-bold Motivo de rechazo
        span {{diagnostic.comentario_rechazo ? diagnostic.comentario_rechazo : '-' }}
    div.d-flex.justify-content-between.mb-3.row
      div.details.col-4
        label.font-weight-bold Técnico
        span {{diagnostic.tecnico.first_name +' '+ diagnostic.tecnico.last_name }}
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import hasPermissions from '../../utils/permissions'
import ActionSelectTechnician from '../OT/OTActions/ActionSelectTechnician.vue'
import UpdateDiagnostic from './UpdateDiagnostic.vue'

export default {
  name: 'Diagnostic',
  components: { ActionSelectTechnician, UpdateDiagnostic },
  data() {
    return {
      loading: true,
      user: false,
      rol: [],
    }
  },
  async created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.user = user
    const rol = this.user.groups.filter((f) => f.id === 2)
    this.rol = rol
    await this.getLastDiagnostic(this.ot.diagnostico)
    this.loading = false
    this.listTechnicians()
  },
  methods: {
    ...mapActions('Diagnosticos', ['getLastDiagnostic']),
    ...mapActions('TechniciansStore', ['listTechnicians']),
    ...mapActions('Diagnosticos', ['showDiagnosticForm']),
    ...mapMutations('Diagnosticos', ['SET_DIAGNOSTIC']),
    showForm() {
      this.showDiagnosticForm(true)
    },
  },
  computed: {
    ...mapGetters('Diagnosticos', ['diagnostic']),
    ...mapGetters('OTStore', ['ot']),
    canEdit() {
      if (this.ot.diagnostico) {
        return (
          this.ot.estado &&
          this.ot.estado.estado.nombre.toLowerCase() === 'en reparación' &&
          hasPermissions({
            roles: ['Técnico', 'Supervisor', 'Administrativo'],
            user: { user: this.user },
          })
        )
      }
      return (
        this.ot.estado &&
        this.ot.estado.estado.nombre.toLowerCase() === 'en reparación' &&
        hasPermissions({
          roles: ['Técnico', 'Supervisor'],
          user: { user: this.user },
        })
      )
    },
  },
  filters: {
    beautyDate(date) {
      //eslint-disable-line
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
  },
  destroyed() {
    this.showDiagnosticForm(false)
  },
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
}
.title {
  color: #364599;
  font-weight: bold;
}
.details {
  display: flex;
  flex-direction: column;
}
.title-data {
  color: #253590;
  font-weight: 700;
  font-size: 1.2em;
}
.reporte-text {
  align-items: start;
}
</style>

<template lang="pug">
b-card.sill-card
  .mb-3
    h5.table-header {{ headerTitle }}

  b-table(
    :items="items"
    :fields="fields"
    head-row-variant="light-primary"
    tbody-class="tabla-tbody"
    thead-class="text-black tabla-thead"
    tbody-tr-class="bg-white"
    responsive
    show-empty
    no-local-sorting
    empty-text="No hay datos para mostrar"
    :busy="loading"
  )
    template(#table-busy)
      div(class="text-center text-danger my-2")
        b-spinner(class="align-middle")

    template(#cell(text)="{ item }")
      p(:class="getText(item) === 'Total' ? 'footer-color' : ''") {{ getText(item) }}

    template(#cell(count)="{ item }")
      p(:class="getText(item) === 'Total' ? 'footer-color' : ''") {{ item.count }}

    template(#cell(percentage)="{ item }")
      p(:class="getText(item) === 'Total' ? 'footer-color' : ''") {{ getPercentage(item.percentage) }} %

</template>

<script>
import { mapState } from 'pinia';
import { useSummaryReportStore } from '@/pinia/views/useSummaryReportStore';

export default {
  props: {
    hideOTColumn: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: 'Nombre de la tabla',
    },
    keyTable: {
      type: String,
      default: 'key',
    },
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  computed:{
    ...mapState(useSummaryReportStore, ['loading'])
  },
  methods: {
    getText(item) {
      let text = ''
      if (this.keyTable === 'summary_diag_results') {
        text = item.result
      }
      if (this.keyTable === 'summary_models') {
        text = item.model
      }
      if (this.keyTable === 'summary_repairs') {
        text = item.model
      }
      if (this.keyTable === 'summary_repairs_technician') {
        text = item.result
      }
      if (this.keyTable === 'summary_eval' || this.keyTable === 'summary_pieces') {
        text = item.label
      }
      return text.charAt(0).toUpperCase() + text.slice(1)
    },
    getPercentage(percentage) {
      if (this.isDecimal(percentage)) {
        if (percentage === undefined) return 100
        return percentage.toFixed(2)
      }
      return percentage
    },
    isDecimal(n) {
      return n % 1 !== 0
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  color: #5B73E8;
  font-size: 18px;
  font-weight: bold;
}
.footer-color {
  color: #5B73E8;
  font-size: 14px;
  font-weight: bold;
}
</style>

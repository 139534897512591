<template lang="pug">
Fragment
  .span-full
    div.head.d-none.d-lg-flex
      .d-flex
        b-icon.no-print.mr-2(icon="clipboard-data" variant="primary" font-scale="1.5")
        h4.mb-0.title Datos Cliente

      .d-flex.align-items-center.no-print.gap-1
        ChangeDeliveryButton(:ot="ot", :public="false")
        ChangeServiceTypeButton(:ot="ot")
        router-link.expand-on-hover-lg(:to="`/editar-orden/${ot.ot}`" v-if="ot.estado.estado.nombre !== 'En Solicitud de Excepción'")
          b-icon( icon="pencil-square" font-scale="1.5" variant="primary" )
    // Vista Solo en pantallas móviles (sm) y Tablets (md)
    div.head.d-sm-block.d-md-block.d-lg-none
      b-row 
        b-col(cols="12" md="6")
          .d-flex.align-items-center.gap-2
            b-icon.no-print.mr-2(icon="clipboard-data" variant="primary" font-scale="1.5")
            h4.mb-0.title Datos Cliente
            
            .ml-auto
              router-link.expand-on-hover-lg(:to="`/editar-orden/${ot.ot}`" v-if="ot.estado.estado.nombre !== 'En Solicitud de Excepción'")
                b-icon(icon="pencil-square" font-scale="1.5" variant="primary")

        b-col(cols="12" md="6").mt-2
          .d-flex.align-items-center.no-print.gap-1
            ChangeDeliveryButton(:ot="ot", :public="false")
        b-col(cols="12" md="6").mt-2
          ChangeServiceTypeButton(:ot="ot")
    hr
  div.details
    label.sub-title.is-primary R.U.T
    span.display-data {{ot.cliente.persona? ot.cliente.persona.rut : ot.cliente.empresa.rut}}
  div.details
    label.input-label Tipo de Cliente
    span.display-data {{ot.cliente.tipo_cliente}}
  div.details
    label.input-label Nombre o Razón Social
    span.display-data
      | {{ot.cliente.empresa && ot.cliente.tipo_cliente === "Empresa" ? ot.cliente.empresa.razon_social : `${ot.cliente.persona.nombre} ${ot.cliente.persona.apellido}` }}
  div(v-if="ot.cliente.persona").details
    label.input-label Email
    span.display-data
      | {{ot.cliente.persona && ot.cliente.tipo_cliente === "Persona" ? ot.cliente.persona.email : JSON.parse(ot.cliente.empresa.correo_empresas)[0]}}
  div(v-if="ot.cliente.persona").details
    label.input-label Contacto Telefono
    span.display-data {{ot.cliente.persona.telefono ? ot.cliente.persona.telefono : ot.sucursal ? ot.sucursal.telefono : '-' }}
  div(v-if="ot.cliente.empresa").details
    label.input-label Sucursal
    span.display-data {{ot.sucursal? ot.sucursal.nombre : 'N/A'}}
  div(v-if="ot.cliente.empresa").details
    label.input-label Teléfono Sucursal
    span.display-data {{ ot.sucursal ? ot.sucursal.telefono : '-'}}
  div(v-if="ot.cliente.empresa").details
    label.input-label Correo Sucursal
    .d-flex.flex-column(v-if="ot.sucursal && ot.sucursal.email")
      span(v-for="email in JSON.parse(ot.sucursal.email)") {{email}}
    span(v-else).display-data N/A
  div.details
    label.input-label Región
    span.display-data
      | {{ot.cliente.persona && ot.cliente.tipo_cliente === "Persona" ? ot.cliente.persona.region : ot.sucursal ? ot.sucursal.region : '-' }}
  div.details
    label.input-label Comuna
    span.display-data
      | {{ot.cliente.persona && ot.cliente.tipo_cliente === "Persona" ? ot.cliente.persona.comuna : ot.sucursal ? ot.sucursal.comuna : '-' }}
  div.details
    label.input-label Dirección
    span.display-data
      | {{ot.cliente.persona && ot.cliente.tipo_cliente === "Persona" ? ot.cliente.persona.direccion : ot.sucursal ? ot.sucursal.direccion : '-' }}
</template>

<script>
import { mapGetters } from 'vuex'
import * as cts from '../constants'
import ChangeDeliveryButton from '../ClientOT/ChangeDeliveryButton.vue'
import ChangeServiceTypeButton from './ChangeServiceTypeButton.vue'

export default {
  name: 'ClientData',
  components: {
    ChangeDeliveryButton,
    ChangeServiceTypeButton,
  },
  data() {
    return {
      editing: false,
      form: {
        id: null,
        rut: null,
        tipo_cliente: cts.CLIENTE_PERSONA,
        nombre: null,
        apellido: null,
        email: null,
        telefono: null,
        direccion: null,
        comuna: null,
        region: null,
        razon_social: null,
      },
    }
  },
  computed: {
    ...mapGetters('OTStore', ['ot', 'printNoDisplay']),
  },
  created() {
    if (!this.ot.cliente.persona && !this.ot.cliente.empresa) {
      this.$router.push(`/editar-orden/${this.ot.ot}`)
    }
  },
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: #364599;
  font-weight: 700;
  font-size: 1.2em;
}
.sub-title {
  color: #364599;
  font-weight: 700;
  font-size: 1em;
}
.input-label {
  color: #74788d;
  font-weight: bold;
  font-size: 1em;
}
.display-data {
  font-size: 1em;
}
.details {
  display: flex;
  flex-direction: column;
  color: #74788d;
}
p {
  font-size: 15px;
}
.title2 {
  color: #253590;
  font-weight: bold;
  font-style: 15px;
}
</style>

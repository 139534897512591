<template lang="pug">
div.form-empresa
  b-row
    b-col
      b-form-group( label-for="input-razon_social" )
        template( #label ) Razón Social
        b-form-input(
          id="input-razon_social"
          v-model="$v.form.razon_social.$model"
          :state="validateState('razon_social')"
          :disabled="disabled"
        )
        b-form-invalid-feedback Campo requerido

  b-row
    b-col
      b-form-group( label-for="input-correos-empresa" )
        template( #label ) Correos Electrónicos
        b-form-tags(
          input-id="input-correos-empresa"
          v-model="form.correos_empresa"
          placeholder="Agregar correos separados por espacio..."
          invalid-tag-text="Dirección de correo inválida: "
          separator=" "
          tag-variant="primary"
          :tag-validator="validateEmail"
          :disabled="disabled"
        )
        template( #invalid-feedback ) Debe ingresar al menos una dirección de correo
  b-row
    b-col
      b-form-group( label-for="input-correo-cambio" )
        template( #label ) Correos de cambios
        b-form-tags(
          id="input-correo-cambio"
          v-model="form.correo_cambio"
          placeholder="Agregar correos separados por espacio..."
          invalid-tag-text="Dirección de correo inválida: "
          separator=" "
          tag-variant="primary"
          :tag-validator="validateEmail"
          :disabled="disabled"
        )
  b-row
    b-col
      Sucursales(
        ref="sucursales"
        v-if="idCliente && hasEmpresa"
        v-model="$v.form.sucursal.$model"
        :state="validateState('sucursal')"
        :clientIdOverride="idCliente"
        :disabled="disabled"
        @modal-closed="form.sucursal = null"
      )
</template>

<script>
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import validateEmail from '@/utils/validateEmail'
import Sucursales from './Sucursales.vue'

export default {
  components: { Sucursales },
  props: {
    disabled: Boolean,
    value: {
      type: Object,
      default: () => ({
        razon_social: null,
        correos_empresa: [],
        correo_cambio: [],
        sucursal: null,
      }),
    },
    idCliente: null,
    hasEmpresa: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {}
  },
  validations: {
    form: {
      razon_social: { required },
      correos_empresa: {
        required,
      },
      correo_cambio: {},
      sucursal: {
        required: requiredIf(function fn() {
          return this.idCliente && this.hasEmpresa
        }),
        notCreating: minValue(0),
      },
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    // Validación removida, pasar este método al :state del b-form-tags para restaurar
    // correosTagState() {
    //   const { $dirty } = this.$v.form.correos_empresa;
    //   return $dirty ? (this.form.correos_empresa.length > 0) : null;
    // },
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (val.correos_empresa !== oldVal.correos_empresa) {
          this.$v.form.correos_empresa.$touch()
        }
      },
      deep: true,
    },
  },
  methods: {
    validateEmail,
    validateState(name) {
      const { $dirty, $invalid } = this.$v.form[name]
      return $dirty ? !$invalid : null
    },
    resetForm() {
      this.form = {}
      this.$nextTick(() => this.unTouch())
    },
    unTouch() {
      this.$v.form.$reset()
    },
    validate() {
      this.$v.form.$touch()
      return !this.$v.form.$invalid
    },
    openSucursalProvisory(datosProvisorios) {
      this.$refs.sucursales.openSucursalProvisory(datosProvisorios)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.form-empresa::v-deep {
  .form-group label {
    color: #74788d;
  }
}

::v-deep .b-form-tags-button {
  padding: 0;
}

::v-deep #input-correos-empresa {
  @include media-breakpoint-down(sm) {
    padding: 10px 0 !important;

    &::placeholder {
      font-size: 13px;
      white-space: pre-line;
      position: relative;
      top: -10px;
    }
  }
}
</style>

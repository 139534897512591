<template lang="pug">
b-card.sill-card
    b-row
        b-col(sm="12" md="6" lg="5" class="mb-2 mb-md-0 ")
          b-nav(v-if="hasMultipleRoles" card-header pills).m-0
            b-nav-item(
              v-for="role in userRoles"
              :key="role.name"
              :active="currentTab === role.name"
              @click="currentTab = role.name"
            ) {{ role.name }} ({{ role.count }})     
        b-col(sm="12" md="6" lg="2" class="mb-2 mb-md-0")
            p.d-sm-block.d-md-none.mb-2.mt-2.font-weight-bold Selecciona rango de fecha:
            b-form-datepicker(
              v-model="filters.dateStart"
              placeholder="Fecha de Inicio"
              today-button reset-button close-button
              label-today-button="Hoy"
              label-reset-button="Limpiar"
              label-close-button="Cerrar"
              label-help="Puede usar las flechas para navegar."
              locale="es"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
            )
        b-col(sm="12" md="6" lg="2" class="mb-2 mb-md-0")
            b-form-datepicker(
              v-model="filters.dateEnd"
              placeholder="Fecha de Fin"
              today-button reset-button close-button
              label-today-button="Hoy"
              label-reset-button="Limpiar"
              label-close-button="Cerrar"
              label-help="Puede usar las flechas para navegar."
              locale="es"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
            )
        b-col(sm="12" md="6" lg="2" class="mb-2 mb-md-0")
            
            b-select(
              v-if="hasMultipleRoles"
              v-model="filters.user"
              :options="users"
            )
              template(#first)
                b-select-option(:value="null") Filtrar por usuario
        b-col(sm="12" md="6" lg="1" class="mb-2 mb-md-0")
            b-button(
              variant="button"
              class="float-right"
              @click="filters.page = 1; getActionLogs()"
            ) Buscar
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useUserReportStore } from '@/pinia/views/useUserReportStore'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(useUserReportStore, ['userRoles', 'users', 'hasMultipleRoles']),
    ...mapWritableState(useUserReportStore, ['currentTab', 'filters']),
  },
  methods: {
    ...mapActions(useUserReportStore, ['getActionLogs']),
  },
  watch: {
    currentTab() {
      this.filters.page = 1
      this.getActionLogs()
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-pills .nav-link {
  background-color: var(--light);
  color: var(--gray);
  font-weight: bold;
  &.active {
    background-color: var(--button);
    color: var(--white);
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  > * {
    min-width: 200px;
    font-size: 14px;
  }
  > *:last-child {
    flex-shrink: 1.1;
  }
}
</style>

<template lang="pug">

.list-container( v-if="tabsLoaded" )
  b-row
    // Mostrar solo en pantallas móviles (sm)
    b-col(cols="6" sm="4").d-sm-block.d-md-none
      FilterTechnicians

    // Mostrar solo en pantallas md en adelante
    b-col(cols="6" sm="8").d-none.d-md-block
      ButtonsFilters(:showInDashboard="false")  

    // Mostrar solo en pantallas móviles (sm)
    b-col(cols="6" sm="2").d-sm-block.d-md-none
      DropdownFilters

    // FilterTechnicians al final en pantallas de escritorio
    b-col(cols="6" sm="4").d-none.d-md-block
      FilterTechnicians




  GenericOTList(:tabs="tabs", :keys="keys")
.loading-container( v-else )
  b-spinner( label="Loading..." variant="primary" )
</template>

<script>
/** @module ListOTIndex
 * Brinda el contexto para definir las tabs y columnas relevantes en al lista de ordenes de trabajo,
 * de acuerdo a los roles que tiene el usuario logueado
 */
import { mapActions, mapGetters } from 'vuex'
import { mapWritableState } from 'pinia'
import { useDashboardStore } from '@/pinia/views/useDashboardStore'
import ButtonsFilters from '@/components/Dashboard/ButtonsFilters.vue'
import DropdownFilters from '@/components/Dashboard/DropdownFilters.vue'
import FilterTechnicians from './FilterTechnicians.vue'
import GenericOTList from './GenericOTList.vue'
import BackArrow from '../../UI/BackArrow.vue'

export default {
  name: 'ListOTIndex',
  components: {
    GenericOTList,
    BackArrow,
    ButtonsFilters,
    FilterTechnicians,
    DropdownFilters
  },
  data() {
    return {
      tabs: [
        {
          title: 'Pendientes',
          states: [], // si no filtra traerá todos
        },
      ],
      tabsLoaded: false,
      keys: [
        'ot',
        'fecha_ingreso',
        'rma',
        'serie',
        'tecnico',
        'ubicacion',
        'estado',
        'all_time',
        'state_time',
        'acciones',
      ].filter((k) => {
        //eslint-disable-line
        const keysGroup = []
        const { user } = JSON.parse(localStorage.getItem('user'))
        // const tecnico = user.groups.find((g) => g.name === 'Técnico');
        // if (tecnico && user.groups.length === 1) return k !== 'ver_pedido';
        // return k;
        user.groups.forEach((g) => {
          if (g.name === 'Administrativo')
            keysGroup.push(
              'ot',
              'fecha_ingreso',
              'rma',
              'serie',
              'tecnico',
              'ubicacion',
              'estado',
              'all_time',
              'state_time',
              'acciones'
            )
          if (g.name === 'Bodega')
            keysGroup.push(
              'ot',
              'fecha_ingreso',
              'rma',
              'serie',
              'tecnico',
              'ubicacion',
              'estado',
              'all_time',
              'state_time',
              'acciones'
            )
          if (g.name === 'Gerencia')
            keysGroup.push(
              'ot',
              'fecha_ingreso',
              'rma',
              'serie',
              'tecnico',
              'ubicacion ',
              'estado',
              'all_time',
              'state_time',
              'acciones'
            )
          if (g.name === 'Supervisor')
            keysGroup.push(
              'ot',
              'fecha_ingreso',
              'rma',
              'serie',
              'tecnico',
              'ubicacion ',
              'estado',
              'all_time',
              'state_time',
              'acciones'
            )
          if (g.name === 'Técnico')
            keysGroup.push(
              'ot',
              'fecha_ingreso',
              'rma',
              'serie',
              'tecnico',
              'ubicacion',
              'estado',
              'all_time',
              'state_time',
              'acciones'
            )
        })
        return keysGroup.includes(k)
      }),
    }
  },
  methods: {
    ...mapActions('OT', ['getSillStates', 'setDefaultStates']),
    async init() {
      await this.getSillStates()
      const statesNames = [] // Inicializamos para guardar los nombres, esto nos servirá para agregar a todas

      // Agrega tabs correspondientes a estados del usuario
      this.userStates.forEach((userState) => {
        const args = { title: userState.nombre, states: [userState.nombre] }
        if (userState.nombre === 'Reparado') {
          args.params = { aprobadas_3x3: false }
        }
        this.tabs.push(args)
        statesNames.push(userState.nombre)
      })

      this.tabs[0].states = [...statesNames]
      statesNames.push('Cerrado')

      // Simula tab 3x3
      const reparadoIndex = statesNames.indexOf('Reparado')
      if (reparadoIndex > -1) {
        this.tabs.push({
          title: 'Reparado 3x3',
          states: ['Reparado'],
          params: { aprobadas_3x3: true },
        })
      }

      // Tabs todas
      this.tabs.push({ title: 'Todas', states: statesNames })

      this.setDefaultStates(this.tabs[0].states)

      this.tabsLoaded = true
    },
  },
  computed: {
    ...mapGetters('OT', ['userStates']),
    ...mapWritableState(useDashboardStore, ['filters']),
  },
  created() {
    this.init()
    this.filters.tecnico = null
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #74788d;
  font-weight: bold;
  margin-right: 20px;
}
.second-admission {
  background-color: #f1b44c;
  color: #495057;
}
.drop-button {
  background-color: white;
  color: grey;
  font-size: 12px;
  border: none;
  height: 5vh;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-left: 5%;
  margin-right: 1%;
  padding-bottom: 3%;
  padding-top: 3%;
  outline: none;
  display: inline-block;
  width: 150px;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(128, 124, 124, 0.2);
  top: 40px;
  z-index: 1;
}
#searchBar {
  margin-right: 3%;
  margin-left: 3%;
  width: 70%;
}
.searchBar {
  margin-right: 3%;
  margin-left: 3%;
}
.searchForm {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  .searchInput {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
  }
  .create-button {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    margin-bottom: 1%;
  }
  ::v-deep .dropdown .myclass {
    color: white !important;
  }
  .list-container {
    background-color: white !important;
    height: 900px;
  }
  .table-size {
    height: 650px;
  }
  ::v-deep .page-link {
    color: #5b73e8;
  }
  ::v-deep .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #5b73e8 !important;
    border-color: #5b73e8 !important;
  }
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #5b73e8 !important;
  border-color: #5b73e8 !important;
}
</style>

<template lang="pug">
#PartCreation
  b-modal(v-model="model" size="xl" scrollable @ok="savePart")
    template(#modal-header)
      .flex-col
        .h5.modal-title Cambiar pieza:
        .flex-row.gap-1
          span Pieza anterior:
          span RMA: {{ ot.rma }}
          span -
          span Código: {{ originalPart.part_number }}
          span -
          span {{ originalPart.descripcion }}
    b-form
      b-table(
        :fields="tableFields"
        :items="parts"
        responsive
      )
        template( #cell(partNumber)="{ index, item: part }" )
          b-form-group
            SILLAutocomplete(
              @click.native="onclick(index)"
              :search="searchParts"
              displayAttribute="part_number"
              placeholder="Ej: AF3FC3F"
              :onSubmit="onPartSelected"
              :searching="searching"
              @keyup.native="setPartNumber(index)"
              v-bind:key="index"
              :class="getAutoCompleteClass(index)"
            )
            b-form-invalid-feedback(
              :state="validateState(`$each.$iter[${index}].part_number`)"
            ) Campo requerido

        template( #cell(codigo)="{ item: part }" )
          VueBarcode(
            v-model="part.part_number"
            width=1 height=30 textAlign="center" fontSize="13")

        template( #cell(description)="{ index, item: part }" )
          b-form-group
            b-form-input(
              v-model="part.descripcion"
              :state="validateState(`$each.$iter[${index}].description`)"
              :formatter="formatter"
              style="text-transform:uppercase;"
            )
            b-form-invalid-feedback Campo requerido

        template( #cell(realLocation)="{ item: part }" )
          b-form-input(v-model="part.real_location")
    //- b-button(size='sm' variant='success' @click='savePart()')
    //-   | Guardar
</template>
<script>
import { mapActions } from 'vuex'
import SILLAutocomplete from '@/components/UI/SILLAutocomplete.vue'
import VueBarcode from 'vue-barcode'
import { get as _get } from 'lodash'
import { required } from 'vuelidate/lib/validators'

export default {
  components: { SILLAutocomplete, VueBarcode },
  data() {
    return {
      searching: false,
      partNumberInputText: '',
      selectedPart: null,
      parts: [],
      originalPart: {},
      tableFields: [
        { key: 'index', label: '#' },
        { key: 'partNumber', label: 'Part Number' },
        { key: 'codigo', label: 'Código' },
        { key: 'description', label: 'Descripción' },
        { key: 'realLocation', label: 'Real Location' },
      ],
      requestedPartId: null,
    }
  },
  validations: {
    parts: {
      $each: {
        allocated: { required },
        parte: {
          partNumber: { required },
          description: { required },
        },
      },
    },
  },
  props: ['part', 'value', 'ot'],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    part: {
      handler(part) {
        this.init()
        console.log('part', part)
        this.originalPart = {
          part_number: part.part_number,
          descripcion: part.descripcion,
        }
      },
      immediate: true,
    },
    // partsCreationModal() {
    //   this.localModal = this.partsCreationModal
    // },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('Diagnosticos', ['searchByPartNumber']),
    ...mapActions('PartsStore', ['changePart']),
    ...mapActions('OTStore', ['getOT']),
    init() {
      if (this.part) {
        this.parts = [this.part]
        this.requestedPartId = this.part.id
      }
    },
    onPartSelected(partSelected) {
      this.selectedPart = partSelected
    },
    async savePart() {
      const response = await this.changePart({
        solicitud_id: this.requestedPartId,
        parte: this.parts[0],
      })

      if (response.status === 200) {
        this.$bvToast.toast('Pieza Actualizada', {
          variant: 'success',
          title: 'Actualización Exitosa',
          autoHideDelay: 5000,
        })

        const nuevoHistorial = {
          pieza_original: {
            part_number: this.part.code,
          },
          pieza_nueva: this.parts[0],
          fecha_cambio: new Date(),
        }
        this.$emit('pieza-actualizada', nuevoHistorial)
        this.$emit('closeModal')
      } else {
        this.$bvToast.toast('Error al actualizar pieza', {
          variant: 'error',
          title: 'Error',
        })
      }
      const otId = this.$route.params.order_id

      await this.getOT(otId)
      this.$emit('closeModal')
    },
    setPartNumber(index) {
      this.parts[index].part_number = this.partNumberInputText
      // this.$v.parts.$each.$iter[index].part_number.$model = this.partNumberInputText
    },
    getAutoCompleteClass(index) {
      const state = this.validateState(
        `$each.$iter[${index}].parte.part_number`
      )
      return state
        ? 'autocomplete-valid'
        : state === false
        ? 'autocomplete-invalid'
        : ''
    },
    onclick(index) {
      if (this.selectedPart) {
        console.log('selectedPart', this.selectedPart)
        this.parts[index].descripcion = this.selectedPart.description
        this.parts[index].part_number = this.selectedPart.part_number
        this.parts[index].real_location = this.selectedPart.real_location
        this.parts[index].id = this.selectedPart.id
      }
      this.selectedPart = null
    },
    validateState(fieldPath) {
      const field = _get(this.$v.parts, fieldPath)
      if (!field) return null
      const { $dirty, $error } = field
      return $dirty ? !$error : null
    },
    formatter(value) {
      if (!value || !value.length) return null
      return value.toUpperCase().substr(0, 10)
    },
    async searchParts(partNumber) {
      this.partNumberInputText = partNumber
      if (partNumber.length <= 2) {
        return []
      }
      this.searching = true
      const resp = await this.searchByPartNumber({
        partNumber,
        shortQuery: true,
      })
      this.searching = false
      let partes = resp.data.results
      if (resp.data.results.length === 0) {
        partes = [
          {
            description: null,
            id: null,
            part_number: partNumber,
            real_location: null,
          },
        ]
      }
      return partes
    },
  },
}
</script>
<style lang="scss"></style>

<template lang="pug">
  .printBarCode
    button(class="btn-print" size="sm" @click="showModal = true")
      font-awesome-icon(:icon="['fa', 'barcode']")
      | Generar código
    //- Modal para mostrar codifo de barras
    b-modal.modal(v-model="showModal")
      div.d-flex.justify-content-center.align-items-center.flex-column(id="barcode")
        VueBarcode(v-bind:value="getBarcodeData" width=2 height=28 textAlign="center" fontSize="14" style="margin-top: 7.2cm")
        center
          .bar-code-info.bold OT:{{ ot.ot }}-{{ ot.tipo_servicio.id === 1 ? 'GARANT' : 'PRESUP' }}
          .bar-code-info {{barCodeInfo}}
      template(#modal-footer='{ ok, cancel, hide }')
        b-button(size='sm' variant='success' @click="printBarCode") Imprimir
        b-button(size='sm' variant='danger' @click='showModal = false') Cerrar
</template>

<script>
import { mapGetters } from 'vuex'
import VueBarcode from 'vue-barcode'

export default {
  name: 'PrintBarCode',
  components: {
    VueBarcode,
  },
  data() {
    return {
      showModal: false,
      barCodeInfo: null,
    }
  },
  methods: {
    printBarCode() {
      const win = window.open(
        '',
        '',
        'left=0,top=0,width=600,height=500,toolbar=0,scrollbars=0,status =0'
      )
      let content = '<html>'
      content += '<body onload="window.print(); window.close();">'
      content += document.getElementById('barcode').innerHTML
      content += '</body>'
      content += '</html>'
      win.document.write(content)
      win.document.close()
    },
  },
  computed: {
    ...mapGetters('OTStore', ['ot']),
    getBarcodeData() {
      const empresa =
        this.ot.cliente && this.ot.cliente.empresa
          ? this.ot.cliente.empresa.razon_social
          : null
      const persona =
        this.ot.cliente && this.ot.cliente.persona
          ? `${this.ot.cliente.persona.nombre} ${this.ot.cliente.persona.apellido}`
          : null
      if (this.ot?.cliente?.tipo_cliente === 'Empresa' && empresa) {
        this.barCodeInfo = empresa // eslint-disable-line
      } else if (this.ot?.cliente?.tipo_cliente === 'Persona' && persona) {
        this.barCodeInfo = persona // eslint-disable-line
      }
      const barcodeText = `${this.ot.ot}`
      return barcodeText
    },
  },
}
</script>

<style lang="scss" scoped>
.bar-code-info {
  line-height: 12px;
  font-size: 14px;
}
.bold {
  font-weight: bold;
}
::v-deep .modal-dialog {
  height: -webkit-fill-available;
}
::v-deep .modal.show .modal-dialog {
  margin-top: 10%;
  transform: none;
}
#barcode {
  margin-top: -256px;
}
::v-deep .btn-print {
  top: 921px;
  left: 1675px;
  width: 170px;
  height: 45px;
  /* UI Properties */
  background: #34c38f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: bold;
  margin-top: 10px;

}
/* Estilos para pantallas menores a 992px */
@media (max-width: 576px) {
  ::v-deep .btn-print {
    background: none;
    border: none;
    color: #63C093;
    width: auto;
    height: auto;

    font-weight: normal;
    box-shadow: none;
    margin-top: 5px;
    margin-left: 0px;
    padding-left: 0 !important;
    margin-left: -5px;
  }
  
  ::v-deep .btn-print:hover {
    background: none !important;
  }
  ::v-deep .btn-print .svg-inline--fa {
    display: none;
  }
}
::v-deep .btn-print:hover {
  background: #2fac7e 0% 0% no-repeat padding-box;
}
.svg-inline--fa {
  margin-right: 5px;
}
</style>

<template lang="pug">
#PartsTable
  b-table(:items="items" :fields="fields" responsive)
    template(#cell(part_number)="value")
      VueBarcode(v-bind:value="ot.rma" width=1 height=30 textAlign="center" fontSize="15")
    template.code(#cell(code)="value")
      VueBarcode(v-model="value.item.part_number" width=1 height=20 textAlign="center" fontSize="13")
    template(#cell(real_location)="value")
      div {{value.item.real_location }}
    template(#cell(acciones)="value")
      b-button(v-if="value.item.estado === 'Solicitado'" @click="handleCancelPart(value.item.id)") Cancelar
      RadioButtonSelectState(:value="value.item" :ot="ot")
    template(#cell(llegada)="value")
      b-button(v-if="value.item.estado === 'Solicitado'"  @click="handleChangePart(value.item)") Cambiar Pieza
    template(#cell(historial)="value")
      EyeIcon(v-if="value.item.historial.length > 0"  @view-details="showModal(value.item.historial)")
      div(v-if="value.item.historial.length === 0" class="text-center") -
    button.save(@click="saveData" v-if="$route.name !== 'AgregarDiagnostico' && parts !== null") Guardar
  PartCreation(
    :ot="ot"
    :part="partSelected"
    v-if="partSelected"
    v-model="partsCreationModal"
    @closeModal="closeModal"
    @pieza-actualizada="actualizarHistorial(partSelected, $event)"
  )
  b-modal(v-model="showModalFlag" title="Historial de cambios de pieza", size="lg")
    b-table(:items="dataHistorial" :fields="historialFields" responsive bordered)
      template(#cell(date)="value")
        div {{ value.date }}
      template(#cell(action)="value")
        div {{ value.action }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import VueBarcode from 'vue-barcode'
import StatesSelect from './StatesSelect.vue'
import RadioButtonSelectState from './RadioButtonSelectState.vue'
import PartCreation from './PartCreation.vue'
import EyeIcon from './EyeIcon.vue'

export default {
  name: 'PartsTable',
  components: { StatesSelect, RadioButtonSelectState, PartCreation, VueBarcode, EyeIcon },
  props: [
    'ot',
    'showStateActionColumn',
    'showArrivalColumn',
    'handleArrival',
    'filterStates',
    'filterAllocated',
  ],
  data() {
    return {
      parts: null,
      partsCreationModal: false,
      droppedRequest: false,
      tecnico: null,
      realLocation: null,
      partSelected: null,
      showModalFlag: false,
      dataHistorial: [],
      historialFields: [
        { key: 'pieza_original', label: 'Pieza original' },
        { key: 'pieza_nueva', label: 'Pieza Nueva' },
        { key: 'fecha_cambio', label: 'Fecha Cambio' }
      ]
    }
  },
  beforeCreate() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.tecnico = user.groups.find((g) => g.name === 'Técnico')
  },
  async created() {
    await this.getAllUsers()
  },
  computed: {
    ...mapGetters('Warehouse', ['allUsers']),
    fields() {
      let fields = [
        { key: 'part_number', label: 'Rma' },
        { key: 'code', label: 'Código' },
        { key: 'allocated', label: 'Allocated' },
        { key: 'fecha_hora_solicitud', label: 'Solicitud' },
        { key: 'fecha_hora_entrega', label: 'Entrega' },
        { key: 'descripcion', label: 'Descripción' },
        { key: 'real_location', label: 'Real Location' },
        { key: 'estado', label: 'Estado' },
        { key: 'acciones', label: 'Acciones' },
        { key: 'historial', label: 'Historial' }
      ]
      // eslint-disable-next-line
      fields = fields.filter((f) => {
        if (this.showStateActionColumn === true) return f.key !== 'estado' && f.key !== 'historial'
        if (this.showStateActionColumn === false) return f.key !== 'acciones'
        if (this.filterStates) return f.key !== 'acciones'
      })
      if (this.showArrivalColumn) {
        fields = fields.concat([{ key: 'llegada', label: 'Acciones' }]).filter(f => f.key !== 'historial');
      }
      return fields
    },
    items() {
      return this.ot.solicitud_partes
        ?.filter((parte) => {
          if (this.filterStates && !this.filterAllocated) {
            return parte.allocated === this.filterAllocated
          }
          return true
        })
        .filter((parte) => {
          if (this.filterStates && this.filterStates.length > 0) {
            return this.filterStates.includes(parte.estado)
          }
          return true
        })
        .map((parte) => { //eslint-disable-line
          const estadosNuevosNombres = {
            Usado: 'Bad',
          }
          return {
            allocated: parte.allocated ? 'Sí' : 'No',
            part_number: parte.parte ? parte.parte.part_number : '-',
            fecha_hora_solicitud: parte.fecha_hora_solicitud ? moment(parte.fecha_hora_solicitud).format('DD-MM-YYYY HH:mm') : '-',
            fecha_hora_entrega: parte.fecha_hora_entrega ? moment(parte.fecha_hora_entrega).format('DD-MM-YYYY HH:mm') : '-',
            code: parte.parte ? parte.parte.part_number : '',
            descripcion: parte.parte ? parte.parte.description : '-',
            real_location: parte.parte ? parte.parte.real_location : '-',
            estado: estadosNuevosNombres[parte.estado]
              ? estadosNuevosNombres[parte.estado]
              : parte.estado,
            // _rowVariant: parte.dropped && this.$route.name !== 'AgregarDiagnostico' ? 'info' : '',
            id: parte.id,
            historial: parte.registro,
          }
        })
    },
  },
  methods: {
    ...mapActions('Diagnosticos', ['updateRequests', 'cancelPart']),
    ...mapActions('Warehouse', ['getAllUsers']),
    ...mapActions('Notifications', ['sendNotification']),
    getPartColor() {
      return 'color: #253590; font-weight: bold;'
    },
    handleChangePart(part) {
      this.partsCreationModal = true
      this.partSelected = part
      console.log('change part', part)
    },
    closeModal() {
      this.partsCreationModal = false
      console.log('close modal')
    },
    actualizarHistorial(item, nuevoHistorial) {
      if (item.historial) {
        item.historial.push(nuevoHistorial);
      } else {
        this.$set(item, 'historial', nuevoHistorial);
      }
    },
    async saveData() {
      const data = []
      this.items.forEach((item) => {
        data.push({
          ot: this.ot.ot,
          solicitudId: item.id,
          parte: {
            partNumber: item.part_number,
            description: item.descripcion,
            realLocation: item.real_location,
          },
          allocated: item.allocated === 'No' ? false : true, //eslint-disable-line
        })
      })
      const resp = await this.updateRequests(data)
      if (resp && resp.status === 200) {
        this.$bvToast.toast(`${resp.data}`, {
          variant: 'success',
        })
        this.sendPartNotification()
      }
    },
    async sendPartNotification() {
      const users = this.allUsers.filter((u) => { //eslint-disable-line
        return u.groups.find((g) => g.name === 'Bodega')
      })
      const userIds = users?.map((u) => u.id)
      userIds.push(this.ot.tecnico_asignado.id)
      const anyAllocated = this.items.find(
        (p) => p.estado_pieza === 'Localizado'
      )
      if (anyAllocated) {
        const notificationData = {
          recipients: userIds,
          ot: this.ot.ot,
          message: 'Llegó parte solicitada',
          send_by_email: false,
          type_message: 2,
        }
        const rsp = await this.sendNotification(notificationData)
        console.log(rsp)
      }
      this.parts = []
    },
    async handleCancelPart(requestPartId) {
      console.log(this.ot.ot, requestPartId)
      const resp = await this.cancelPart({ otId: this.ot.ot, parte_solicitada_id: requestPartId })
      if (resp && resp.status === 200) {
        this.$bvToast.toast('Solicitud de parte eliminada.', {
          variant: 'success',
        })
        this.$emit('changed')
      }
    },
    showModal(historial) {
      if (historial.length > 0) {
        this.dataHistorial = historial
        .sort((a, b) => new Date(b.fecha_cambio) - new Date(a.fecha_cambio))
        .map(pieza => ({
          pieza_original: pieza.pieza_original.part_number,
          pieza_nueva: pieza.pieza_nueva.part_number,
          fecha_cambio: moment(pieza.fecha_cambio).format('DD-MM-YYYY HH:mm')
        }));
        this.showModalFlag = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.save {
  color: #fff;
  background-color: #5b73e8;
  border-color: #5b73e8;
  width: 120px;
  height: 45px;
  border-radius: 5px;
  font-weight: 600;
}
</style>

<template lang="pug">
  .select-technician
    div
      //- BOTON ENTREGAR A TECNICO
      //- Da salida de bodega a un equipo asignado a un técnico para que lo diagnostique o repare
      button.change-button(
        :disabled="disabled"
        title="Entregar a técnico"
        @click="entregarATecnico()")
        | Entregar equipo a técnico
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ActionEntregarTecnicoSinPartes',
  props: ['ot', 'disabled'],

  data() {
    return {
      state: false,
      usuarios: [],
    }
  },
  methods: {
    ...mapActions('OTStore', ['entregarEquipoTecnicoSinPartes']),
    async entregarATecnico() {
      const resp = await this.entregarEquipoTecnicoSinPartes({
        otId: this.ot.ot,
        technitianId: this.ot.tecnico_asignado.id,
      })
      if (resp.status === 200) {
        this.$bvToast.toast(
          `OT entregada a ${this.ot.tecnico_asignado.first_name} ${
            this.ot.tecnico_asignado.last_name
          }
        a las ${moment(resp.data.fecha_hora).format('DD/MM/YYYY HH:mm')}`,
          {
            variant: 'success',
          }
        )
        this.$emit('changed')
      } else {
        this.$bvToast.toast(`Error al entregar el equipo: ${resp.data}`, {
          variant: 'danger',
        })
      }
    },
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"PartCreation"}},[_c('b-modal',{attrs:{"size":"xl","scrollable":""},on:{"ok":_vm.savePart},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"flex-col"},[_c('div',{staticClass:"h5 modal-title"},[_vm._v("Cambiar pieza:")]),_c('div',{staticClass:"flex-row gap-1"},[_c('span',[_vm._v("Pieza anterior:")]),_c('span',[_vm._v("RMA: "+_vm._s(_vm.ot.rma))]),_c('span',[_vm._v("-")]),_c('span',[_vm._v("Código: "+_vm._s(_vm.originalPart.part_number))]),_c('span',[_vm._v("-")]),_c('span',[_vm._v(_vm._s(_vm.originalPart.descripcion))])])])]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('b-form',[_c('b-table',{attrs:{"fields":_vm.tableFields,"items":_vm.parts,"responsive":""},scopedSlots:_vm._u([{key:"cell(partNumber)",fn:function(ref){
var index = ref.index;
var part = ref.item;
return [_c('b-form-group',[_c('SILLAutocomplete',{key:index,class:_vm.getAutoCompleteClass(index),attrs:{"search":_vm.searchParts,"displayAttribute":"part_number","placeholder":"Ej: AF3FC3F","onSubmit":_vm.onPartSelected,"searching":_vm.searching},nativeOn:{"click":function($event){return _vm.onclick(index)},"keyup":function($event){return _vm.setPartNumber(index)}}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validateState(("$each.$iter[" + index + "].part_number"))}},[_vm._v("Campo requerido")])],1)]}},{key:"cell(codigo)",fn:function(ref){
var part = ref.item;
return [_c('VueBarcode',{attrs:{"width":"1","height":"30","textAlign":"center","fontSize":"13"},model:{value:(part.part_number),callback:function ($$v) {_vm.$set(part, "part_number", $$v)},expression:"part.part_number"}})]}},{key:"cell(description)",fn:function(ref){
var index = ref.index;
var part = ref.item;
return [_c('b-form-group',[_c('b-form-input',{staticStyle:{"text-transform":"uppercase"},attrs:{"state":_vm.validateState(("$each.$iter[" + index + "].description")),"formatter":_vm.formatter},model:{value:(part.descripcion),callback:function ($$v) {_vm.$set(part, "descripcion", $$v)},expression:"part.descripcion"}}),_c('b-form-invalid-feedback',[_vm._v("Campo requerido")])],1)]}},{key:"cell(realLocation)",fn:function(ref){
var part = ref.item;
return [_c('b-form-input',{model:{value:(part.real_location),callback:function ($$v) {_vm.$set(part, "real_location", $$v)},expression:"part.real_location"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
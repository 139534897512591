<template lang="pug">
  .filters
      b-form-select.margin(
        v-if="userRole"
        v-model="filters.tecnico"
        :options="technicianOptions"
        :disabled="loading"
      )
  </template>
  <script>
import { mapActions, mapGetters } from 'vuex'
import { mapState, mapWritableState } from 'pinia'
import { useDashboardStore } from '@/pinia/views/useDashboardStore'

export default {
  name: 'FilterByTechnician',
  data() {
    return {
      userRole: null,
    }
  },

  async created() {
    const { user } = JSON.parse(localStorage.getItem('user'))
    this.userRole = user.groups.find(
      (r) =>
        r.name === 'Gerencia' ||
        r.name === 'Administrativo' ||
        r.name === 'Supervisor'
    )
    await this.listTechnicians()
  },

  methods: {
    ...mapActions('TechniciansStore', ['listTechnicians']),

    async removeFilters() {
      this.filters.tecnico = null
    },
  },

  computed: {
    ...mapGetters('TechniciansStore', ['technicians']),
    ...mapWritableState(useDashboardStore, ['filters', 'loading']),
    ...mapState(useDashboardStore, ['queryParams']),

    technicianOptions() {
      const mappedTechnicians = this.technicians.filter((t => t.cantidad_ot > 0)).map((t) => {//eslint-disable-line
        return {
          value: t.tecnico.id,
          text: `${t.tecnico.first_name} ${t.tecnico.last_name} (${t.cantidad_ot}/${t.cantidad_maxima})`, //eslint-disable-line
        }
      }).sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase()) ? -1 : (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : 0);
      mappedTechnicians.unshift({
        value: null,
        text: 'Todos',
      })
      mappedTechnicians.unshift({
        value: null,
        text: 'Filtros por técnico',
        disabled: true,
      })
      return mappedTechnicians;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.margin {
  &:not(:last-child) {
    margin-right: 15px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }
}
.filters {
  display: flex;
  @include media-breakpoint-down(md) {
    display: block;
  }
}
</style>

<template lang="pug">
div
    .container-list
      b-tabs(pills card lazy v-model="selectedTab" @activate-tab="tabActivated")
        //- todo: desparchear la condición de === 'Pendientes' cuando se arregle el contador
        b-tab(
          v-for="(tab, index) in tabs"
          v-if="getStatesCount(index) > 0 || tab.title === 'Pendientes'"
          :title="`${tab.title || tab.states[0]} (${getStatesCount(index)})`"
          :title-item-class="isSearched ? 'd-none' : 'text-black'"
          @click="handleTabChange(index)"
        )
      OtTable(
        ref="otTable"
        :otItems="currentOtsFiltered"
        :loading="loading"
        @changed="reloadCurrentTab"
        @sortingChanged="handleSortingChanged"
      )
    div.d-flex.flex-row-reverse()
      b-pagination(
        v-model="currentPage"
        :total-rows="currentOtsFiltered.count"
        :per-page="pageSize"
        prev-text="Ant."
        next-text="Sig."
        @change="handlePageChange"
      )
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mapState, mapWritableState } from 'pinia'
import { io } from 'socket.io-client'
import settings from '@/../settings'
import { useDashboardStore } from '../../../pinia/views/useDashboardStore'
import OtTable from './OtTable.vue'

export default {
  name: 'GenericOTList',
  props: ['tabs', 'dontSearch', 'otList'], // TODO: documentar props
  components: {
    OtTable,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 20,
      selectedTab: 0,
      selectedIndex: 0,
      loading: false,
      justAdmin: false,
      sortBy: null,
      sortDesc: false,
    }
  },
  async created() {
    if (!this.$route.params.dontSearch) {
      this.loading = true
      console.log('created, fetching data')
      await this.searchOT({
        estados: this.tabs[0].states,
        ot_list: this.otList,
      })
      this.loading = false
    }
    const { user } = JSON.parse(localStorage.getItem('user'))
    const groups = user.groups.filter(
      (u) =>
        u.name === 'Supervisor' || u.name === 'Técnico' || u.name === 'Gerencia'
    )
    const groupsJustAdmin = user.groups.filter(
      (u) => u.name === 'Administrativo'
    )

    this.initWebsockets()

    if (groups.length > 0) return
    if (groupsJustAdmin.length > 0) this.justAdmin = true
  },
  destroyed() {
    if (!this.socket) return
    this.socket.disconnect()
  },
  computed: {
    ...mapGetters('AuthenticationStore', ['user']),
    ...mapGetters('OT', ['currentOts', 'currentStates', 'isSearched', 'isSearching']),
    ...mapGetters('Warehouse', ['stateIsChanged']),
    ...mapWritableState(useDashboardStore, ['filters']),
    ...mapState(useDashboardStore, ['queryParams']),

    /** Reduce las tabs a arreglo de estados para pasárselos a los filtros de búsqueda */
    estados() {
      const states = []
      this.tabs.forEach((tab) => {
        tab.states.forEach((estado) => {
          if (states.indexOf(estado) === -1) {
            states.push(estado)
          }
        })
      })
      return states
    },
    currentOtsFiltered() {
      // deprecated, TODO: rename and delete this property
      return this.currentOts
    },
    watchedFilters() {
      const f = this.filters
      return [f.tipoServicio, f.tipoCliente, f.tecnico, f.on_site || null, f.alertColor]
    },
  },
  methods: {
    ...mapActions('OT', [
      'searchOT',
      'updateOtInCurrentPage',
      'addOtToResults',
      'getSingleOTAsListData',
      'removeOtFromResults',
    ]),
    ...mapMutations('Warehouse', ['SET_STATE_IS_CHANGED']),

    tabActivated(tab, oldtabindex, event) {
      if (this.loading) {
        event.preventDefault();
      }
    },

    handlePageChange(page) {
      console.log('page changed, fetching data')
      this.fetchData(page)
    },
    handleTabChange(index) {
      if (this.loading) return
      console.log('tab changed, fetching data')
      this.selectedIndex = index
      this.fetchData()
    },
    reloadCurrentTab() {
      this.handleTabChange(this.selectedIndex)
    },
    handleSortingChanged(sortBy, sortDesc) {
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      const page = undefined
      let ordering = sortBy
      if (sortDesc && sortBy) ordering = `-${sortBy}`
      console.log('ordering changed, fetching data')
      this.fetchData(page, ordering)
    },

    async fetchData(page = 1, ordering) {
      this.loading = true
      const args = {
        estados: this.tabs[this.selectedIndex].states,
        page,
        ...this.tabs[this.selectedIndex].params,
        ot_list: this.otList,
        filters: this.queryParams,
        ordering,
      }
      await this.searchOT(args)
      this.loading = false
    },
    getStatesCount(index) {
      if (!this.currentOtsFiltered.estados) return 0
      return Object.keys(this.currentOtsFiltered.estados).reduce(
        (accum, current) => {
          if (this.tabs[index].params?.aprobadas_3x3)
            return this.currentOtsFiltered.estados['Reparado 3x3']
          const toAdd =
            this.tabs[index].states.indexOf(current) > -1
              ? this.currentOtsFiltered.estados[current]
              : 0
          return accum + toAdd
        },
        0
      )
    },
    initWebsockets() {
      this.socket = io(settings.webSocketsUrl)

      this.socket.on('orden_trabajo:created', async ({ id }) => {
        console.log('orden_trabajo:created', id)
        const data = await this.getSingleOTAsListData({ ot: id })
        this.addOtToResults({ ot: data })
      })
      this.socket.on('orden_trabajo:updated', async ({ id }) => {
        console.log('orden_trabajo:updated', id)
        const data = await this.getSingleOTAsListData({ ot: id })
        await this.updateOtInCurrentPage({ ot: data })
      })
      this.socket.on('orden_trabajo:deleted', ({ id }) => {
        console.log('orden_trabajo:deleted', id)
        this.removeOtFromResults({ id })
      })

      // this.socket.on('order_updated', async (rawData) => {
      //   console.log('order_updated')
      //   const data = JSON.parse(rawData)
      //   const index = await this.updateOtInCurrentPage({ ot: data })
      //   if (index == null) return
      //   this.$refs.otTable.$refs[`actionsWrapper${index}`].fetchOT()
      // })

      // this.socket.on('order_added', (rawData) => {
      //   console.log('order_added')
      //   const data = JSON.parse(rawData)
      //   this.addOtToResults({ ot: data })
      // })

      // this.socket.on('order_removed', (rawData) => {
      //   console.log('order_removed')
      //   const data = JSON.parse(rawData)
      //   this.revemoOtFromResults({ ot: data })
      // })

      // this.socket.on('order_state_changed', (rawData) => {
      //   console.log('order_state_changed')
      //   const data = JSON.parse(rawData)
      //   const currentOt = this.currentOts.results.find((o) => o.ot === data.ot)
      //   this.revemoOtFromResults({ ot: currentOt })
      //   this.addOtToResults({ ot: data })
      // })
    },
  },
  watch: {
    stateIsChanged(value) {
      if (value) {
        console.log('stateIsChanged, fetching data')
        this.searchOT({
          estados: this.tabs[0].states,
          ot_list: this.otList,
        })
        this.SET_STATE_IS_CHANGED(false)
      }
    },
    watchedFilters: {
      async handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return

        this.loading = true;
        if (this.sortBy) {
          this.handleSortingChanged(this.sortBy, this.sortDesc);
        } else if (!this.$route.params.dontSearch) {
        console.log('watchedFilters && not sorted && dontSearch, fetching data')
          await this.searchOT({
            estados: this.tabs[0].states,
            ot_list: this.otList,
            filters: this.queryParams,
          });
        }
        this.loading = false;
      },
      deep: true,
    },
    isSearching: {
      handler(value) {
        this.loading = value;
      },
    },
    // '$route.params.dontSearch': {
    //   handler(value) {
    //     if (value) this.searchOT();
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    this.filters.tipoServicio = null
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.container-list {
  box-shadow: 0px 3px 6px #00000029;
}

.ver-pedido {
  color: #fff;
  background-color: var(--button);
  border-color: var(--button);
  width: 90px;
  height: 40px;
  border-radius: 5px;
  font-weight: 600;

  &:disabled {
    background-color: #7d93ff;
    border-color: #7d93ff;
    cursor: not-allowed;
  }
}

.ot {
  color: #41509b;
  font-weight: bold;
}

.header-tecnico {
  font-size: 1.2em;
  font-weight: 700;
}

::v-deep .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--button);
  font-weight: bold;
}

::v-deep .card-header {
  background-color: #ffffff;
  overflow-x: auto;

  ul {
    overflow-x: visible;
  }

  a {
    color: #495057;
    font-weight: bold;

    &:hover {
      color: #495057;
    }
  }
}

::v-deep table th {
  color: #495057;
}

a.router-link-active {
  color: var(--button);
}

.text-danger {
  color: var(--button) !important;
}

::v-deep .card-body {
  padding: 0;
}

.text-danger[data-v-76755bd8] {
  color: #d32b2b !important;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--button) !important;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.ot-cell {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

::v-deep .tabla-tbody td {
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

::v-deep .tabla-thead th {
  @include media-breakpoint-down(sm) {
    padding: 4px 10px 4px;
  }
}
</style>

<template lang="pug">
  .select-technician
    div
      //- BOTON REPUESTO LOCALIZADO O ENTREGADO
      //-Da salida de bodega si aplica y entrega a técnico un equipo porque llegaron sus repuestos para retomar reparación
      button.change-button(
        :disabled="disabled"
        title="Entregar a técnico"
        @click="modal = true")
        | Entregar partes a técnico
      b-modal(v-model="modal" title="Confirmar entrega a técnico" @ok="handleEntrega" size='lg')
        p
          | Se entregarán {{ ot.ubicacion === 'Bodega' ? 'equipo y repuestos' : 'respuestos' }}
          | para continuar reparación de OT
          b  {{ ot.ot }}
          |  a {{ ot.tecnico_asignado ? `${ot.tecnico_asignado.first_name} ${ot.tecnico_asignado.last_name}` : '-' }}
        PartsTable(
          :ot="ot"
          :filterStates="['Solicitado','Localizado']"
          :filterAllocated="true"
        )
</template>

<script>
import { mapActions } from 'vuex'
import PartsTable from '../../AskParts/PartsTable.vue'

export default {
  name: 'ActionEntregarEquipoRepuestoLocalizado',
  props: ['ot', 'disabled'],
  components: {
    PartsTable,
  },
  data() {
    return {
      modal: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions('OTStore', ['entregarPartes']),
    hideModal() {
      this.modal = false
    },
    async handleEntrega() {
      try {
        await this.entregarPartes({ otId: this.ot.ot })
        this.$bvToast.toast('Equipo y partes entregadas a técnico', {
          variant: 'success',
        })
        setTimeout(() => this.$emit('changed'), 500)
        this.modal = false
        this.$emit('changed')
      } catch (err) {
        this.$bvToast.toast(err.response.data || 'Error al registrar entrega', {
          variant: 'danger',
        })
      }
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"main-table-card"},[_c('div',{staticClass:"filters"},[_c('ButtonsFilters'),_c('Filters',{attrs:{"otsFiltered":_vm.otsFiltered},on:{"filterData":function($event){return _vm.fillTable($event)}}})],1),_c('b-table',{staticClass:"main-table",attrs:{"fields":_vm.fields,"items":_vm.filteredItems,"busy":_vm.loading,"bordered":"","responsive":"","foot-clone":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(estado)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("header-fase-" + (item.fase))},[_vm._v(_vm._s(item.estado))])]}},_vm._l((_vm.timeFields),function(field){return {key:("cell(" + (field.key) + ")"),fn:function(ref){
var item = ref.item;
var value = ref.value;
var index = ref.index;
return [_c('div',{staticClass:"flex-col position-relative"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{attrs:{"tooltip-left":"OTs con acciones para realizar","tooltip-left-width":"400px"}},[(value.hasActions)?_c('b-icon',{attrs:{"icon":"exclamation","variant":"secondary","font-scale":"2"}}):_vm._e()],1),_vm._l((value.colors),function(color,colorName){return _c('CircularIcon',{key:index + colorName,staticClass:"mr-1 cursor-pointer",attrs:{"number":color.length,"classCustom":colorName},nativeOn:{"click":function($event){return _vm.showOts(color, item.estado)}}})})],2),(_vm.showViewAllButton(value))?_c('b-button',{staticClass:"view-all-button",on:{"click":function($event){return _vm.showOts(value.colors.green.concat( value.colors.yellow, value.colors.red), item.estado)}}},[_vm._v("Ver todas")]):_vm._e()],1)]}}}),{key:"cell(total)",fn:function(ref){
var item = ref.item;
var value = ref.value;
var index = ref.index;
return [_c('div',{staticClass:"total",on:{"click":function($event){_vm.showOts(_vm.getOtsOfItem(item), item.estado)}}},[_vm._v(_vm._s(value))])]}},{key:"foot(estado)",fn:function(ref){return [_c('span',[_vm._v("TOTAL")])]}},_vm._l((_vm.timeFields),function(field){return {key:("foot(" + (field.key) + ")"),fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"total",on:{"click":function($event){_vm.showOts(_vm.getOtsOfRange(column))}}},[_vm._v(_vm._s(_vm.columnTotals[column] || 0))])]}}}),{key:"foot(total)",fn:function(ref){return [_c('b-badge',{staticClass:"text-white total",attrs:{"pill":"","variant":"info"},on:{"click":function($event){return _vm.showOts(_vm.totalSum.otsTotals)}}},[_vm._v(_vm._s(_vm.totalSum.columnTotals))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
#ListParts
  .container-list
    b-card(border-variant="light")
      strong Ingresar Búsqueda
      b-row
        b-col(md="6" sm="12")
          b-input-group.mt-2
            b-input-group-prepend
              b-button(@click="handleSearch" variant="light")
                b-icon(icon="search" variant="muted" size="md")
            b-form-input(
              v-model="search"
              placeholder="Ingresar el Código de la Pieza / Descripción / Ubicación Real"
              @keydown.enter="handleSearch"
              @keydown.esc="handleClear"
            )
            b-input-group-append
              b-button(@click="handleClear" variant="light")
                b-icon(icon="x" variant="muted" size="md")
        b-col(md="6").d-none.d-md-block
          b-button.mt-2(variant="button" to="/inventario/ingreso-inventario/ingreso") + Ingresar Nuevo
          b-button.mt-2.ml-3.btn-ingreso(variant="estado-yellow" to="/inventario/ingreso-inventario/drop") Rebaja DROP
          b-button.mt-2.ml-3.btn-ingreso(variant="button-light" to="/inventario/ingreso-inventario/scrap") SCRAP
          b-button.mt-2.ml-3.btn-ingreso(variant="estado-green" to="/inventario/ingreso-inventario/rtv") RTV
          b-button.mt-2.ml-3.btn-ingreso(variant="estado-red" to="/inventario/ingreso-inventario/slow") SLOW   
          b-button.mt-2.ml-3(variant="button", @click="exportExcel()") Exportar Piezas     
        b-col(sm="12" class="d-block d-md-none ").mt-2
          b-dropdown(text="Opciones" variant="button" menu-class=" dropdown-menu" right)
            b-dropdown-item
              b-button.mt-2.btn-responsive(variant="primary" class="text-white" to="/inventario/ingreso-inventario/ingreso") Ingresar Nuevo
            b-dropdown-item
              b-button.mt-2.btn-ingreso(variant="estado-yellow" to="/inventario/ingreso-inventario/drop") Rebaja DROP
            b-dropdown-item
              b-button.mt-2.btn-ingreso(variant="button-light" to="/inventario/ingreso-inventario/scrap") SCRAP
            b-dropdown-item
              b-button.mt-2.btn-ingreso(variant="estado-green" to="/inventario/ingreso-inventario/rtv") RTV
            b-dropdown-item
              b-button.mt-2.btn-ingreso(variant="estado-red" to="/inventario/ingreso-inventario/slow") SLOW
            b-dropdown-item
              b-button.mt-2.btn-responsive(variant="primary" class="" @click="exportExcel()") Exportar Piezas  
     
    b-table(
      :items="tableItems",
      :fields="fields",
      head-row-variant="light-primary",
      thead-class="text-black tabla-thead",
      tbody-class="tabla-tbody",
      tbody-tr-class="bg-white",
      responsive,
      :busy="loading",
      show-empty,
      empty-text="No hay Piezas disponibles"
    )
      template(#cell(part_number)='value')
        .d-flex.justify-content-start
          router-link(:to="`/inventario/historial-pieza/${value.item.part_number}/${value.item.id}`" style="font-weight: bold;") {{value.item.part_number}}
      template(#cell(real_location)='value')
        .d-flex.justify-content-start
          div(style="font-weight: bold; color:#253590") {{value.item.real_location}}
      template(#table-busy)
        div(class="text-center text-danger my-2")
          b-spinner(class="align-middle")
  .d-flex.flex-row-reverse
    b-pagination(
      v-model="currentPage",
      :total-rows="count",
      :per-page="pageSize",
      prev-text="Ant.",
      next-text="Sig.",
      @change="handlePageChange"
    )
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'ListParts',
  data() {
    return {
      search: '',
      currentPage: 1,
      pageSize: 20,
      tableItems: [],
      count: 0,
      loading: false,
      fields: [
        { key: 'part_number', label: 'Código de la Pieza', sortable: true },
        { key: 'real_location', label: 'Ubicación Real', sortable: true },
        { key: 'description', label: 'Descripción' },
        { key: 'cantidad_buenos', label: 'Buenos', sortable: true },
        { key: 'cantidad_allocated', label: 'Allocated', sortable: true },
        { key: 'cantidad_drop', label: 'Drop', sortable: true },
        { key: 'cantidad_malos', label: 'Malos', sortable: true },
      ],
    }
  },
  methods: {
    ...mapActions('PartsStore', ['listParts', 'getPartsExcel']),
    exportExcel() {
      this.getPartsExcel();
    },
    async handlePageChange(page) {
      this.fetchData(page)
    },
    async fetchData(page = 1) {
      this.loading = true
      const res = await this.listParts({ page, search: this.search })
      this.tableItems = res.results
      this.count = res.count
      this.loading = false
    },
    handleSearch() {
      this.fetchData(this.currentPage)
    },
    handleClear() {
      this.search = ''
      this.fetchData(this.currentPage)
    },
  },
  created() {
    this.fetchData()
  },
}
</script>
<style lang="scss">
.container-list {
  box-shadow: 0px 3px 6px #00000029;
}
.btn-ingreso {
  width: 130px;
  color: black !important;
  font-weight: bold !important;
  font-size: 14px !important;
  align-items: center;
  opacity: 0.7;
}
.btn-responsive {
  width: 130px;
  font-weight: bold !important;
  font-size: 14px !important;
  align-items: center;
}
</style>

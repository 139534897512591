import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { getHeaders } from '../api/utils'

export const useSummaryReportStore = defineStore('summaryReport', {
  state: () => ({
    filters: {
      dateStart: null,
      dateEnd: null,
      users: [],
    },
    loading: true,
    users: [],
    results: [],
    reports: [
      {
        title: 'Resultados del diagnóstico',
        key: 'summary_diag_results',
        fields: [
          { key: 'text', label: 'Resultado' },
          { key: 'count', label: 'Cantidad de OTs' },
          { key: 'percentage', label: 'Porcentaje' },
        ],
        items: []
      },
      {
        title: 'Tiempo Total en Evaluación',
        key: 'summary_eval',
        fields: [
          { key: 'text', label: 'Días en evaluación' },
          { key: 'count', label: 'Cantidad de OTs' },
          { key: 'percentage', label: 'Porcentaje' },
        ],
        items: []
      },
      {
        title: 'Repuestos Usados',
        key: 'summary_pieces',
        fields: [
          { key: 'text', label: 'Cantidad de Repuestos' },
          { key: 'count', label: 'Cantidad de OTs' },
          { key: 'percentage', label: 'Porcentaje' },
        ],
        items: []
      },
      {
        title: 'Modelos más Utilizados',
        key: 'summary_models',
        fields: [
          { key: 'text', label: 'Modelo' },
          { key: 'count', label: 'Cantidad de OTs' },
          { key: 'percentage', label: 'Porcentaje' },
        ],
        items: []
      },
      {
        title: 'Cantidad de Reparaciones',
        key: 'summary_repairs',
        fields: [
          { key: 'text', label: 'Cant. de reparaciones' },
          { key: 'count', label: 'Cantidad de equipos' },
          { key: 'percentage', label: 'Porcentaje' },
        ],
        items: []
      },
      {
        title: 'Cantidad de Reparaciones por Técnico',
        key: 'summary_repairs_technician',
        fields: [
          { key: 'text', label: 'Técnico' },
          { key: 'count', label: 'Cant. Reparaciones' },
          { key: 'percentage', label: 'Porcentaje' },
        ],
        items: []
      }
    ]
  }),

  getters: {
    getReports: (state) => {
      state.reports.forEach(report => {
        report.items = state.results[report.key]
      })
      return state.reports
    },

    queryParams: (state) => {
      const f = state.filters
      const dateStartParam = f.dateStart ? `${f.dateStart}T00:00:00` : null
      const dateEndParam = f.dateEnd ? `${f.dateEnd}T23:59:59` : null
      return {
        fecha_ingreso__gte: dateStartParam,
        fecha_ingreso__lte: dateEndParam,
        tecnico_asignado_id: f.users.map(u => u.value).join(',')
      }
    },

    techniciansParams: (state) => {
      return {
        fecha_ingreso__gte: state.queryParams.fecha_ingreso__gte,
        fecha_ingreso__lte: state.queryParams.fecha_ingreso__lte,
      }
    },
  },

  actions: {

    async getSummaryReport() {
      this.loading = true
      const url = '/api/orden/ot_summary_report'
      const params = this.queryParams

      const config = { params, ...getHeaders() }
      const res = await axios.get(url, config).catch((error) => error.response)
      this.loading = false
      this.results = res.data
    },
    async sendExcelReport() {
      const url = '/api/orden/ot_summary_report_excel'
      const params = this.queryParams
      
      const config = { params, ...getHeaders() }
      try {
        const res = await axios.get(url, config).catch((error) => error.response)
        return res;
      } catch (error) {
        return error;
      }
    },

    initializeDateFilter() {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
      const lastDay = new Date(date.getFullYear(), date.getMonth(), 0)
      // eslint-disable-next-line
      this.filters.dateStart = firstDay.toISOString().split('T')[0]
      // eslint-disable-next-line
      this.filters.dateEnd = lastDay.toISOString().split('T')[0]
    }
  },
})

<template lang="pug">
  b-modal(
    v-model="showModal"
    size="lg"
    :title="`Detalle Aprobación 3x3 OT ${this.ot.ot}`"
    @hide="hideModal"
    hide-footer
  )
    b-row
      b-col
        h6.labels Cliente
        p {{ clienteNombre  }}
      b-col
        h6.labels Fecha de compra
        p {{ ot.fecha_de_compra ? ot.fecha_de_compra : '-' | moment('DD/MM/YYYY')}}
      b-col
        h6.labels R.M.A
        p {{ ot.rma }}
    b-row
      b-col(v-if="ot.diagnostico_detail && ot.diagnostico_detail.informe && ot.diagnostico_detail.informe.trim() !== ''")
        h6.labels Diagnóstico
        p {{ ot.diagnostico_detail.informe }}
    b-row
      b-col(v-if="ot.diagnostico_detail && ot.diagnostico_detail.solucion && ot.diagnostico_detail.solucion.trim() !== ''")
        h6.labels Solución
        p {{ ot.diagnostico_detail.solucion }}
    div(v-if="exception")  
          b-row 
            b-col
              h6.labels Creación OT
              p {{ ot.fecha_ingreso | formatedDate  }} 
            b-col 
              h6.labels Sucursal
              p {{ ot.sucursal ? ot.sucursal.nombre : ot.tienda_empresa ? ot.tienda_empresa.razon_social : '-' }}   
            b-col 
              h6.labels N° de serie  
              p {{ ot.equipo ? ot.equipo.serial_number : '-' }}  
          b-row 
            b-col
              h6.labels Cliente solicita 3x3
              p {{ot.exige_cambio_3x3 ? 'Sí' : 'No' }}   
            b-col(md='4' v-if="boletaUrl")
              h6.labels Boleta
              p(@click="download" class="link-boleta")
                b-icon(icon="download" class="mr-2")
                | Descargar boleta
          b-row
            b-col
              h6.labels Comentario Solicitud
              p {{exception.comentario_solicitud ? exception.comentario_solicitud : 'No hay comentario'}}    
          b-row
            b-col
              h6.labels Comentario Respuesta
              b-badge(variant="danger" v-if="!exception.comentario_respuesta") Sin Respuesta
              | {{ exception.comentario_respuesta }}   


    slot(name="modal-footer")
      div(class="d-flex justify-content-end")
        b-button(variant="primary" class="float-end" @click="hideModal") Aceptar                                   
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DetailsApproval',
  props: {
    exception: {
        type: Object,
      },
      ot: {
        type: Object,
      }
  },
  data () {
  return {
      boletaUrl: null,
      boletaType: null,
      boletaName: null,
  }
},
  computed: {
    ...mapGetters('Exceptions', [
      'showModal'
    ]),
    ...mapGetters('OTStore', ['files']),
    getBoleta() {
      let boleta = this.files.filter((b) => b.name.includes('boleta'))
      if (boleta.length > 0) {
        boleta = boleta[0] // eslint-disable-line
      } else {
        boleta = null
      }
      return boleta
    },
    showModal: {
      get() {
        return this.$store.getters['Exceptions/showModal'];
      },
      set(value) {
        this.SET_SHOW_MODAL(value);
      }
    },
    clienteNombre() {
        const { cliente } = this.ot;
        
        if (cliente.tipo_cliente === 'Empresa') {
          return cliente.empresa.razon_social;
        }
          return `${cliente.persona.nombre} ${cliente.persona.apellido}`;
    }
  },
  methods: {
    ...mapMutations('Exceptions', [
      'SET_SHOW_MODAL'
    ]),
    ...mapActions('OTStore', ['downloadFile', 'getFiles', 'getOT', 'getFileUrl']),
    hideModal() {
      this.SET_SHOW_MODAL(false);
    },
    async download() {
        await this.getFiles(this.ot.ot)
        console.log('getBoleta')
        if (this.getBoleta) {
          const payload = {
            fileName: this.getBoleta.name,
            fileType: this.getBoleta.file_type,
            fileId: this.getBoleta.file_id,
          }
          await this.downloadFile(payload).catch((error) =>
            this.$bvToast.error(error)
          )
        } else {
          this.$bvToast.toast('La OT no tiene boleta almacenada', {
            variant: 'danger',
          })
        }
      },
  },
  async mounted() {
    await this.getFiles(this.ot.ot)
    const item = this.files?.find((f) => f.type === 'BOLETA')
    if (!item) return null

        const payload = {
          fileName: item.name,
          fileType: item.file_type,
          fileId: item.file_id,
        }
        this.boletaUrl = await this.getFileUrl(payload)
        console.log('URL boleta ', this.boletaUrl)
        this.boletaType = item.file_type
        this.boletaName = item.name
  },
};
</script>

<style>
.labels {
color: #74788d;
font-weight: bold;
font-size: 0.9rem;
}
.device-image {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}
.device-icon {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-top: 5px;
}
.link-boleta {
  cursor: pointer;
}
</style>

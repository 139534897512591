import { render, staticRenderFns } from "./ViewOTIndex.vue?vue&type=template&id=4e52f790&scoped=true&lang=pug"
import script from "./ViewOTIndex.vue?vue&type=script&lang=js"
export * from "./ViewOTIndex.vue?vue&type=script&lang=js"
import style0 from "./ViewOTIndex.vue?vue&type=style&index=0&id=4e52f790&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e52f790",
  null
  
)

export default component.exports